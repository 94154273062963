<template>
  <UiPortal>
    <TransitionGroup
      class="bottom-m gap-xxs pointer-events-none fixed inset-x-0 z-[80] flex max-h-screen w-full flex-col-reverse items-center justify-start"
      v-if="isTransitionGroupShown"
      v-bind="transitionClasses"
      tag="ul"
      appear
      @after-leave="afterNoMessagesLeft"
    >
      <UiSnackbar
        v-for="snackbar in snackbarMessages"
        :key="snackbar.id"
        as="li"
        :snackbar="snackbar"
        :classes="{ root: 'relative w-full' }"
        @close="close(snackbar.id!)"
      />
    </TransitionGroup>
  </UiPortal>
</template>

<script setup lang="ts">
const { snackbarMessages, close } = useSnackbar()

const isTransitionGroupShown = ref(false)
const hasMessages = computed(() => snackbarMessages.value.length > 0)

// Makes sure the ul element is hidden with a transition when there are no messages left
const afterNoMessagesLeft = () => {
  if (!hasMessages.value) {
    isTransitionGroupShown.value = false
  }
}

watchEffect(() => {
  if (hasMessages.value) {
    isTransitionGroupShown.value = hasMessages.value
  }
})

const transitionClasses = computed(() => {
  const base = FinqTransitions.scale

  return {
    ...base,
    enterActiveClass: cn(base.enterActiveClass, "max-h-[800px] transition-all"),
    enterFromClass: cn(base.enterFromClass, "max-h-[0px]"),
    leaveActiveClass: cn(base.leaveActiveClass, "max-h-[800px] transition-all"),
    leaveFromClass: cn(base.leaveFromClass, "max-h-[800px]"),
    leaveToClass: cn(base.leaveToClass, "max-h-[0px]"),
  } as Record<keyof typeof base, string>
})
</script>

<style lang="scss">
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 440px;
}
.fadeHeight-enter-to,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
