<template>
  <component class="align-center flex" :is="as" role="alert">
    <div v-bind="$attrs" :class="classNames.root">
      <div class="flex flex-grow items-center md:flex-wrap">
        <div class="gap-s flex flex-grow items-center md:items-start">
          <div class="flex items-center" v-if="snackbar.type && snackbar.type !== 'normal'">
            <UiNuxtIcon :name="snackbar.type" :size="25" filled />
          </div>
          <UiSpinner class="flex items-center" v-if="snackbar.loader" />
          <div v-bind="attributes?.text" :class="classNames.text">
            <slot>
              <span v-text="snackbar.text"></span>
            </slot>
          </div>
        </div>

        <div class="gap-s md:mt-xs flex items-center md:block md:w-full">
          <UiButton
            v-if="snackbar.action"
            v-bind="attributes?.actionButton as any"
            variant="alternative"
            :class="classNames.actionButton"
            :to="snackbar.action.href"
            @click="clickFunctionWrapper(snackbar.action as VoidFunction)"
          >
            {{ snackbar.action?.label ?? $t("common.accept") }}
          </UiButton>

          <UiButton
            v-if="snackbar.cancel"
            v-bind="attributes?.cancelButton as any"
            variant="ghost"
            :class="classNames.cancelButton"
            :to="snackbar.cancel.href"
            @click="clickFunctionWrapper(snackbar.cancel.action as VoidFunction)"
          >
            {{ snackbar.cancel?.label ?? $t("common.cancel") }}
          </UiButton>
        </div>
      </div>

      <UiButton v-if="snackbar.showCloseButton" :class="classNames.closeButton" variant="ghost">
        <UiNuxtIcon
          name="close-icon-white"
          :aria-label="$t('common.close')"
          aria-hidden="true"
          :size="25"
          filled
          @click="clickFunctionWrapper(props.snackbar.close as VoidFunction)"
        />
      </UiButton>
    </div>
  </component>
</template>

<script lang="ts">
import { HTMLAttributes } from "nuxt/dist/app/compat/capi"

import { SnackbarMessage } from "../../../types/snackbar"
</script>

<script setup lang="ts">
export interface UiSnackbarClasses {
  root: ClassValue
  text: ClassValue
  actionButton: ClassValue
  cancelButton: ClassValue
  closeButton: ClassValue
}

export interface UiSnackbarProps {
  unsetAll?: boolean
  classes?: Partial<UiSnackbarClasses>
  attributes?: Partial<Record<keyof Omit<UiSnackbarClasses, "root">, HTMLAttributes>>
  snackbar: SnackbarMessage
  as: string
}

const emit = defineEmits(["close"])

const props = withDefaults(defineProps<UiSnackbarProps>(), { as: "div" })

const classNames = computed((): Required<UiSnackbarClasses> => {
  return {
    root: cn(
      "z-1 p-xs pointer-events-auto relative flex min-h-[73px] min-w-[344px] max-w-[730px] rounded-lg bg-[#1E1E1E] text-white shadow-lg",
      props.classes?.root
    ),
    text: cn("me-s md:pe-xxs flex items-center break-all text-lg font-medium leading-none", {
      "ms-0": props.snackbar.type === "normal",
    }),
    actionButton: cn(
      "h-m min-w-[90px] bg-[#494949] text-base font-bold text-white hover:bg-[#494949]/50 md:w-full"
    ),
    cancelButton: cn(
      "text-base text-[#aeaeae] !no-underline hover:bg-[#494949]/50 hover:text-white md:w-full"
    ),
    closeButton: cn(
      "!ms-s md:end-xxs md:top-xxs -m-1 aspect-square h-fit justify-end rounded-full p-1 hover:bg-[#494949]/50 md:absolute"
    ),
  }
})

const closeSnackbar = () => emit("close")

const clickFunctionWrapper = (clickFunction: VoidFunction) => {
  clickFunction?.()
  closeSnackbar()
}

onMounted(() => {
  if (props.snackbar.timeout) {
    setTimeout(() => closeSnackbar(), props.snackbar.timeout * 1000)
  }
})
</script>
